import React from 'react'
import { Kinetiq } from 'kinetiq'
import { ModalProvider as ModalHookProvider } from 'react-modal-hook'
import { ModalProvider as ModalPlacementProvider } from 'styled-react-modal'
import { TransitionGroup } from 'react-transition-group'
import { ModalBackground } from './src/components/Modal'

export const wrapRootElement = ({ element }) => (
  <Kinetiq>
    <ModalPlacementProvider backgroundComponent={ModalBackground}>
      <ModalHookProvider container={TransitionGroup}>{element}</ModalHookProvider>
    </ModalPlacementProvider>
  </Kinetiq>
)
