// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/destinations/qoor.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/destinations/qoor.io/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/destinations/qoor.io/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/destinations/qoor.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sol-js": () => import("/opt/build/repo/destinations/qoor.io/src/pages/sol.js" /* webpackChunkName: "component---src-pages-sol-js" */),
  "component---src-pages-starship-js": () => import("/opt/build/repo/destinations/qoor.io/src/pages/starship.js" /* webpackChunkName: "component---src-pages-starship-js" */)
}

