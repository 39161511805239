import styled from 'styled-components'
import Modal, { BaseModalBackground } from 'styled-react-modal'

export const ModalBackground = styled(BaseModalBackground)`
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(32px);
`

export default Modal
